/* eslint-disable no-console*/
import React, { useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  makeStyles,
  RACCOLOR,
  RACTable,
  RACTableCell,
  RACTableRow,
  RACToggle,
} from '@rentacenter/racstrap';
import clsx from 'clsx';
import { ALE_LIST_API_LIMIT, INITIAL_OFFSET } from '../../constants/constants';
import {
  ApiStateWrapper,
  Loader,
} from '../../common/ApiStateWrapper/ApiStateWrapper';
import {
  useAleCustomer,
  useAleCustomerActions,
} from '../../context/AleCustomer/AleCustomerProvider';

import { AgreementInfo, AleLeaseDTO } from '../../types/types';
import { yearToDateFormater } from '../../utils/utils';

const useClasses = makeStyles((theme: any) => ({
  customerListWrapper: {
    overflowX: 'auto',
    height: theme.typography.pxToRem(700),
  },
  header: {
    fontSize: '1rem',
    marginBottom: '.5rem',
  },
  cardBody: {
    padding: '.5rem',
    width: '100%',
    boxSizing: 'border-box',
  },
  checkbox: {
    padding: '0',
  },
  noLeftPadding: {
    paddingLeft: 0,
  },
  selected: {
    backgroundColor: 'none !important',
  },
  statusPadding: {
    width: '15%',
  },
  leftPadding21: {
    paddingLeft: theme.typography.pxToRem(21),
  },
  paddingTopBottom8: {
    paddingTop: '.5rem',
    paddingBottom: '.5rem',
  },
  rightField: {
    marginRight: theme.typography.pxToRem(5),
  },
  link: {
    color: RACCOLOR.BLUE_CRAYOLA,
    cursor: 'pointer',
  },
  contentHeight: {
    height: theme.typography.pxToRem(500),
  },
  tipRoot: {
    borderLeftWidth: theme.typography.pxToRem(6),
  },
}));

const tableHeaderList = [
  '',
  'Store Number',
  'Agreement Number',
  'Agreement Description',
  'Monthly Payment',
  'Monthly Tax',
  'Monthly Total',
  'Due Date',
  'Customer FirstName',
  'Customer LastName',
  'Take Payment?',
  'Status',
];
export const customerListWrapperTestId = 'customerListWrapperTestId';

export const CustomerList = () => {
  const classes = useClasses();
  const [offset, setOffset] = useState<number>(INITIAL_OFFSET);

  const colorIdx = useRef(0);
  const [stagedAgreements, setStagedAgreements] = useState(new Set<string>());

  const {
    loading,
    hasApiError,
    aleCustomerDetails,
    exportToPDF,
    paymentResponses,
  } = useAleCustomer();

  const {
    updateAleCount,
    updateAgmLeftCount,
    updateDollarTotal,
    updateStagedAgms,
  } = useAleCustomerActions();

  console.log('loading is ' + loading);

  const updateStagedAgreements = (staged: Set<string>) => {
    updateStagedAgms(staged);
    setStagedAgreements(staged);
  };

  const isStaged = (agreement: AgreementInfo) => {
    return stagedAgreements.has(agreement.agreementId);
  };

  const updateFooterCounts = () => {
    let count = 0;
    let staged = 0;
    let dollarTotal = 0;
    aleCustomerDetails.forEach((customer) => {
      customer.agreement.forEach((agm) => {
        if (isStaged(agm)) {
          staged = staged + 1;
          const agmTot = parseFloat(agm.regularTotal);
          dollarTotal = dollarTotal + (isNaN(agmTot) ? 0 : agmTot);
        } else {
          count = count + 1;
        }
      });
    });
    updateAleCount(staged);
    updateAgmLeftCount(count);
    updateDollarTotal(dollarTotal);
  };

  useEffect(() => {
    updateFooterCounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stagedAgreements]);

  const stageAlePayment = (e: any) => {
    if (e.target.id) {
      const newStage = new Set(stagedAgreements);
      stagedAgreements.has(e.target.id)
        ? newStage.delete(e.target.id)
        : newStage.add(e.target.id);
      updateStagedAgreements(newStage);
    }
  };

  const renderTableHead = () => (
    <>
      {tableHeaderList.map((col, index) => {
        if (index === 11) {
          return (
            <RACTableCell
              key={index}
              padding="checkbox"
              classes={{
                root: clsx(classes.statusPadding),
              }}
            >
              {col}
            </RACTableCell>
          );
        } else if (index === 0) {
          return (
            <RACTableCell
              key={index}
              padding="checkbox"
              classes={{
                root: clsx(classes.leftPadding21, classes.paddingTopBottom8),
              }}
            ></RACTableCell>
          );
        } else {
          return (
            <RACTableCell
              key={index}
              classes={{
                root: classes.paddingTopBottom8,
              }}
            >
              {col}
            </RACTableCell>
          );
        }
      })}
    </>
  );

  const fetchNextPage = () => {
    /*
    fetchNextPage is used by infinite scroll component
    the first fetch is handled by our component, therefore we are not letting the
    infinite scroll to trigger the first request.
    Handles the case when you apply the filters
    (offset set to 0 -> request is triggered, but infinite scroll is also triggering a request, because its data length is 0)
    */

    setOffset(offset + ALE_LIST_API_LIMIT);
  };

  const renderTableContent = () => (
    <>
      {aleCustomerDetails?.map((oneCustomer: AleLeaseDTO) => {
        return oneCustomer.agreement?.map((oneAgm: AgreementInfo) => {
          return (
            <RACTableRow
              key={oneAgm.agreementId}
              hover
              backgroundColor={
                colorIdx.current++ % 2 == 0
                  ? RACCOLOR?.WHITE
                  : RACCOLOR?.ALICE_BLUE
              }
            >
              <RACTableCell></RACTableCell>
              <RACTableCell>{oneCustomer.storeNumber} </RACTableCell>
              <RACTableCell>{oneAgm.agreementNumber} </RACTableCell>
              <RACTableCell>{oneAgm.agreementDescription} </RACTableCell>
              <RACTableCell>{oneAgm.regularRate} </RACTableCell>
              <RACTableCell>{oneAgm.regularTax} </RACTableCell>
              <RACTableCell>{oneAgm.regularTotal} </RACTableCell>
              <RACTableCell>{yearToDateFormater(oneAgm.dueDate)} </RACTableCell>
              <RACTableCell>{oneCustomer.firstName} </RACTableCell>
              <RACTableCell>{oneCustomer.lastName} </RACTableCell>
              <RACTableCell>
                <div className={classes.rightField}>
                  <RACToggle
                    style={{ marginLeft: 0 }}
                    checked={stagedAgreements.has(oneAgm.agreementId)}
                    toggleColor="#A5C8FD"
                    name="toggleMakePayment"
                    id={oneAgm.agreementId}
                    OnChange={(e: any) => {
                      stageAlePayment(e);
                    }}
                  />
                </div>
              </RACTableCell>
              <RACTableCell>
                {paymentResponses.get(oneAgm.agreementId)
                  ? paymentResponses.get(oneAgm.agreementId)
                  : ''}
              </RACTableCell>
            </RACTableRow>
          );
        });
      })}
    </>
  );

  return (
    <div
      className={clsx(classes.customerListWrapper)}
      data-testid={customerListWrapperTestId}
    >
      <InfiniteScroll
        next={fetchNextPage}
        dataLength={5}
        hasMore={false}
        height={500}
        loader={loading ? <Loader /> : null}
      >
        <ApiStateWrapper
          // show loading only for the first request
          loading={loading}
          // show error only if the first request fails
          hasApiError={hasApiError}
          response={aleCustomerDetails}
          noItemAdditionalText={''}
          successContent={
            <div ref={exportToPDF}>
              <RACTable
                renderTableHead={renderTableHead}
                renderTableContent={renderTableContent}
                stickyHeader
              />
            </div>
          }
          classes={{
            loader: classes.contentHeight,
            apiError: classes.contentHeight,
            noItems: classes.contentHeight,
          }}
        />
      </InfiniteScroll>
    </div>
  );
};
